const Tianditu = {
    options:'',
    init(options) {
        if(!options.key || options.key==''){
            options.key='328016e1243b27658e2b50b7cadda87b';
        }
        this.options = options;
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.ref = 'tianditu-script-js'
        script.src = 'http://api.tianditu.gov.cn/api?v=4.0&tk=' + options.key
        document.getElementsByTagName('head')[0].appendChild(script)
        // 引入成功
        script.onload = function () {
            console.log('js资源已加载成功了')
            if (options.el && options.config) {
                var map = new T.Map(options.el, options.config);
                map.enableScrollWheelZoom();
                options.success && options.success(map);
            }else{
                options.success && options.success();
            }
            
        }
        // 引入失败
        script.onerror = function () {
            console.log('js资源加载失败了')
            options.error && options.error();
        }
    },
    destroy(){
        var srcArr=document.getElementsByTagName("script");
        let hasLoaded = false;
        let startindex = 0;
        for (let i = 0; i < srcArr.length; i++) {
            hasLoaded = srcArr[i].src.indexOf('api.tianditu.gov.cn')>0;
            // 如果找到了天地图的js标签将它删除
            if (hasLoaded) {
                srcArr[i].remove();
                if(startindex==0){
                    startindex = i;
                }else{
                    startindex = 0;
                } 
            }
            if(startindex!=0 && startindex<i){
                srcArr[i].remove();
            }
        }
        
    }
}

const install = function (Vue) {
    if (install.installed) return;
    install.installed = true;

    Vue.Tianditu = Tianditu;

    Vue.prototype.$tianditu = Tianditu;
}

export default {
    install,
}