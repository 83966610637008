<template>
  <div>
    <el-tabs class="geology-tabs" v-model="activeTab" @tab-click="onTabClick">
      <el-tab-pane label="基本信息" name="tabBase">
        <el-form ref="form" class="geology-form" label-width="120px">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点名称:">
                {{ dangerDetail.hiddangerModel.DangerName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点编号:">
                {{ dangerDetail.hiddangerModel.DangerCode }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="灾害类型:">
                {{ dangerDetail.hiddangerModel.DangerType }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点等级:">
                {{ dangerDetail.hiddangerModel.DangerLevel }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="岩性:">
                {{ dangerDetail.hiddangerModel.RockType }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="高程:">
                {{ dangerDetail.hiddangerModel.Height }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="坡向:">
                {{ dangerDetail.hiddangerModel.DangerAspect }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="坡度:">
                {{ dangerDetail.hiddangerModel.DangerSlope }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="威胁人数:">
                {{ dangerDetail.hiddangerModel.DangerPeople }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="威胁房屋:">
                {{ dangerDetail.hiddangerModel.DangerHouse }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="威胁财产:">
                {{ dangerDetail.hiddangerModel.DangerProperty }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="经纬度:">
                {{ dangerDetail.hiddangerModel.Longitude }},{{
                  dangerDetail.hiddangerModel.Latitude
                }}
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="地址:">
                {{ dangerDetail.hiddangerModel.Province
                }}{{ dangerDetail.hiddangerModel.City
                }}{{ dangerDetail.hiddangerModel.County
                }}{{ dangerDetail.hiddangerModel.Towns
                }}{{ dangerDetail.hiddangerModel.Village }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">规模</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="危害体积:">
                {{ dangerDetail.hiddangerModel.DangerVolume }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="危害面积:">
                {{ dangerDetail.hiddangerModel.DangerArea }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="诱发因素:">
                {{ dangerDetail.hiddangerModel.DangerFactor }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="发展趋势:">
                {{ dangerDetail.hiddangerModel.DangerTrend }}
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="监测点情况说明:">
                {{ dangerDetail.hiddangerModel.DangerInfo }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">备注信息</el-divider>
          <el-row>
            <el-col :span="19" :offset="1">
              <el-form-item label="备注:">
                {{ dangerDetail.hiddangerModel.Marks }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="图片信息" name="tabImage">
        <div style="padding: 20px 0">
          <el-image
            v-for="(item, index) in dangerDetail.images"
            :key="index"
            style="width: 100px; height: 100px; margin-right: 20px"
            :src="item.FilePath"
            fit="contain"
            :alt="item.Name"
            :preview-src-list="imagePreviewlList"
          >
          </el-image>
        </div>
      </el-tab-pane>
      <el-tab-pane label="文件信息" name="tabFile">
        <el-table
          class="list-table"
          :data="dangerDetail.files"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          :stripe="true"
          :border="false"
        >
          <el-table-column label="文件名称" prop="Name"></el-table-column>
          <el-table-column label="上传时间" prop="CreateTime">
            <template slot-scope="scope">
              {{ scope.row.CreateTime | datetime }}
            </template>
          </el-table-column>
          <el-table-column label="文件地址" prop="FilePath"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="监测设备" name="tabDevice">
        <danger-device :dangerid="detailid"></danger-device>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getDangerDetail } from "@/api/base";
import dangerDevice from "./dangerDevice"
export default {
  data() {
    return {
      activeTab: "tabBase",
      dangerDetail: {
        hiddangerModel: "",
        images: [],
        files: [],
      },
      imagePreviewlList: [],
    };
  },
  props: {
    detailid: [String, Number],
    visible: Boolean,
  },
  components:{
      dangerDevice
  },
  methods: {
    getDangerDetail() {
      getDangerDetail(this.detailid)
        .then((res) => {
          if (res.code == 0) {
            this.dangerDetail = res.data;

            var imagePreviewlList = [];
            this.dangerDetail.images.forEach((item) => {
              imagePreviewlList.push(item.FilePath);
            });
            this.imagePreviewlList = imagePreviewlList;
          }
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });
    },
    onTabClick(item){
        // if(item.index==3){
        //     this.$router.push('/device/index?dangerid='+this.detailid);
        // }
    },
  },
  created() {
    this.getDangerDetail();
  },
  watch: {
    detailid: function () {
      this.getDangerDetail();
    },
    visible: function () {
      if (!this.visible) {
        this.activeTab = "tabBase";
      }
    },
  },
};
</script>