<template>
  <el-dialog
    title="定位"
    :visible.sync="dialogMapVisible"
    width="70%"
    custom-class="geology-dialog geology-dialog-nopadding"
    :modal-append-to-body="true"
    top="5%"
    @open="dialogOpen"
  >
    <div id="geologyDialogMapLocation" v-bind:style="{ height: mapHeight + 'px' }" style="width:100%;border-radius:0 0 6px 6px;"></div>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import Tianditu from "@/common/tianditu";
Vue.use(Tianditu);
export default {
  data() {
    return {
      dialogMapVisible: false,
      tMap: "",
      clientHeight:0,
    };
  },
  props: {
    longitude: {
      type: String,
    },
    latitude: {
      type: String,
    },
  },
  computed:{
      mapHeight:function(){
          return this.clientHeight * 0.7;
      }
  },
  methods: {
    show() {
      this.dialogMapVisible = true;
      this.clientHeight = document.body.clientHeight;
      if (this.tMap) {
        this.showLocation();
      }
    },
    dialogOpen() {
      var that = this;
      if (!this.tMap || this.tMap == "") {
        this.$tianditu.init({
          key: "328016e1243b27658e2b50b7cadda87b",
          el: "geologyDialogMapLocation",
          config: {
            minZoom: 4,
            maxZoom: 20,
            datasourcesControl: false,
          },
          success(map) {
            that.tMap = map;
            map.setMapType(window.TMAP_HYBRID_MAP);

            that.showLocation();
          },
        });
      }
    },
    showLocation() {
      if (this.tMap) {
          this.tMap.clearOverLays();
        var dingwei = {
          name: "定位1",
          lat: this.latitude,
          lng: this.longitude,
        };
        var icon = new T.Icon({
          iconUrl: require("@/assets/map-dw1.png"),
          iconSize: new T.Point(45, 50),
          iconAnchor: new T.Point(22, 50),
        });
        const lnglat = new T.LngLat(dingwei.lng, dingwei.lat);
        this.tMap.centerAndZoom(lnglat, 16);
        var marker = new T.Marker(lnglat, {
          icon: icon,
        });
        this.tMap.addOverLay(marker);
      }
    },
  },
  beforeDestroy(){
    this.$tianditu.destroy();
  },
  watch: {
    longitude: function () {
        this.showLocation();
    },
    latitude: function () {
        this.showLocation();
    },
  },
};
</script>