<template>
  <div class="monitor-index">
    <el-table
      class="list-table list-table-border"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      border
      v-loading="tableLoading"
    >
      <el-table-column
        label="设备名称"
        :show-overflow-tooltip="true"
        prop="DeviceName"
      ></el-table-column>
      <el-table-column label="设备类型" prop="DeviceType"></el-table-column>
      <el-table-column
        label="设备位置"
        :show-overflow-tooltip="true"
        prop="Address"
      ></el-table-column>
      <el-table-column label="状态" prop="IsOn">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.IsOn == true" effect="dark" size="small"
            >在线</el-tag
          >
          <el-tag
            v-if="scope.row.IsOn == false"
            type="danger"
            effect="dark"
            size="small"
            >离线</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="最新时间" prop="LastUpdateTime">
        <template slot-scope="scope">
          {{ scope.row.LastUpdateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <router-link :to="'/device/detail?id=' + scope.row.Id">
            <el-button type="success" icon="el-icon-view" size="mini"
              >详情</el-button
            >
          </router-link>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getDeviceList } from "@/api/device";
export default {
  name: "DeviceList",
  data() {
    return {
      listData: [],
      tableLoading: false,
      listfilter: {
        dangerid: "",
        DeviceType: "",
        state: "",
        fname: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
    };
  },
  props: {
    dangerid: [String, Number],
  },
  components: {},
  methods: {
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDeviceList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
  },
  created() {
    if (this.dangerid) {
      this.listfilter.dangerid = this.dangerid * 1;
    }
    if (this.$route.query.dangerid) {
      this.listfilter.dangerid = this.$route.query.dangerid * 1;
    }
    this.getListData();
  },
  watch: {
    dangerid: function (newvalue) {
      this.listfilter.dangerid = this.dangerid * 1;
      this.getListData();
    },
  },
};
</script>